<template>
    <p class="h20"></p>
    <ul class=" bgf h120 flex" >
        <li v-for="(item,index) in topL" :key="index" class="flex1 flex flexa">
            <el-divider v-if="index!=0" direction="vertical" style="height: 64px; background-color: #E5E5E5" />
            <div class="w100 tc ">
                <p class="col1582 font14 padb10">{{item.txt}}</p>
                <h1 class="colzhuti font28 fontw6">{{ info[item.num] ||0 }}</h1>
            </div>
        </li>
    </ul>
    <p class="h20"></p>

    <div class=" bgf pad20 ">
        <div class="flexab padt14">
           <p class="col24 font14">技术性工单</p> 
           <el-select 
           v-model="param1.customerCSINumber" 
           size="small" 
           class="w242 " 
           @change="getList1"
           multiple 
           filterable
           collapse-tags 
           placeholder="请选择客户服务号">
                <el-option
                v-for="item in workNum"
                :key="item.csiNumber"
                :label="item.csiNumber"
                :value="item.csiNumber">
                </el-option>
            </el-select>
        </div>

        <div class="flexab padt16 padb20">
            <div class="flex flexa ">
                <div class="wmax padr30">
                    <el-checkbox v-model="param1.myCollection" label="我收藏的工单" class="marr20" @change="cgeBox1($event,'myCollection')" ></el-checkbox>
                    <el-checkbox v-model="param1.myAccept" label="我名下的工单" class="marr20" @change="cgeBox1($event,'myAccept')"></el-checkbox>
                    <el-checkbox v-model="param1.myOngoing" label="进行中的工单" @change="cgeBox1($event,'myOngoing')" ></el-checkbox>
                </div>
                <el-input
                    placeholder="请输入内容"
                    v-model="value1"
                    clearable
                    @change="getList1"
                    class=" w300"
                    suffix-icon="el-icon-search"
                    size="small"
                >
                    <template #prepend>
                        <el-select style="width: 120px;font-size:12px" v-model="search1">
                            <el-option label="问题概要" value="1"></el-option>
                            <el-option label="技术请求编号" value="2"></el-option>
                        </el-select>
                    </template>
                </el-input>
            </div>

            <el-button size="small" @click="$router.push('/submit/tech')" type="primary">+创建技术工单</el-button>
        </div>
    </div>
    <p class="h20"></p>
    <div class="bgf" v-loading="loading1">
        <el-table
        :data="info1.voList"
        style="width: 100%">
            <el-table-column
            fixed
            label="问题概要"
            class-name="padl20 padr20"
            show-overflow-tooltip
            min-width="234px">
                <template #default="scope">
                    <div class="flex padr14">
                       <el-link :href=" '#/info/'+scope.row.id " class="block text1" >{{scope.row.problemProfile}}</el-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            prop="outTradeNo"
            fixed
            min-width="140"
            label="技术请求编号"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            prop="categoryName"
            min-width="140"
            label="产品模块"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            prop="createTime"
            label="创建时间"
            sortable
            min-width="140"
            show-overflow-tooltip
            >
                
            </el-table-column>
            <el-table-column
            min-width="140"
            prop="updateTime"
            label="上次更新时间"
            show-overflow-tooltip
            >
            </el-table-column>
             <el-table-column
            prop="problemSeverity"
            min-width="120">
                <template #header>
                    <div>严重等级</div>
                </template>
                <template #default="scoped">
                    <div class="flex flexa">
                        <p :class="[ 'coll'+scoped.row.problemSeverity ,'dian']"></p>
                        <p class="col279 font12 marl6">{{ ['','服务完全丢失','服务严重丢失','少量丢失','未丢失服务'][scoped.row.problemSeverity] }}</p>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
            prop="mainContact"
            label="联系人"
            show-overflow-tooltip
            min-width="80"
            >
            </el-table-column>

            <el-table-column
            label="状态"
            show-overflow-tooltip
            min-width="70"
            >
                <template #default="scoped">
                    {{ statusL[scoped.row.status] }}
                </template>
            </el-table-column>

            <el-table-column
            prop="csiNumber"
            label="客户服务号"
            show-overflow-tooltip
            min-width="120"
            >
            </el-table-column>
            <el-table-column
            label="收藏"
            min-width="70">
                <template #default="scoped">
                    <div @click="colect(scoped.row.id,scoped.$index,1)" class="point">
                        <img v-if="scoped.row.isCollection==1" :src="utils.loadImg('xing-a.png')" alt="" srcset="">
                        <img v-else :src="utils.loadImg('xing.png')" alt="" srcset="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            width="100">
                <template #default="scope">
                    <span @click="handleClick(scope.row)" class="point colblue font12">查看详情</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab w100 borbox">
            <p class="font10 cola4">共 {{info1.total}} 条</p>
            <el-pagination
                v-model:currentPage="param1.pageNo"
                @size-change="(val)=>handleSizeChange(val, 1) "
                @current-change="(val)=>handleCurrentChange(val, 1)"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="param1.pageSize"
                layout=" sizes, prev , jumper , next"
                :total="info1.total">
            </el-pagination>
        </div>
    </div>
    <p class="h20"></p>

    <div class=" bgf pad20 ">
        <div class="flexab padt14">
           <p class="col24 font14">非技术性工单</p> 
           <el-select 
           v-model="param2.customerCSINumber" 
           size="small" 
           class="w242 " 
           @change="getList2"
           multiple 
           collapse-tags 
           filterable
           placeholder="请选择客户服务号">
                <el-option
                v-for="item in workNum"
                :key="item.csiNumber"
                :label="item.csiNumber"
                :value="item.csiNumber">
                </el-option>
            </el-select>
        </div>

        <div class="flexab padt16 padb20">
            <div class="flex flexa ">
                <div class="wmax padr30">
                    <el-checkbox v-model="param2.myCollection" label="我收藏的工单" class="marr20" @change="cgeBox2($event,'myCollection')" ></el-checkbox>
                    <el-checkbox v-model="param2.myAccept" label="我名下的工单" class="marr20" @change="cgeBox2($event,'myAccept')"></el-checkbox>
                    <el-checkbox v-model="param2.myOngoing" label="进行中的工单" @change="cgeBox2($event,'myOngoing')"></el-checkbox>
                </div>
                <el-input
                    placeholder="请输入内容"
                    clearable
                    v-model="value2"
                    @change="getList2"
                    class="w300"
                    suffix-icon="el-icon-search"
                    size="small"
                >
                    <template #prepend>
                        <el-select style="width: 120px;font-size:12px" v-model="search2">
                            <el-option label="问题概要" value="1"></el-option>
                            <el-option label="技术请求编号" value="2"></el-option>
                        </el-select>
                    </template>
                </el-input>
            </div>
            <el-button size="small" @click="$router.push('/submit/untech')" type="primary">+创建非技术工单</el-button>
        </div>
    </div>
    <p class="h20"></p>
    <div class="bgf"  v-loading="loading2">
        <el-table
        :data="info2.voList"
        style="width: 100%">
            <el-table-column
            fixed
            label="问题概要"
            class-name="padl20 padr20"
            show-overflow-tooltip
            min-width="234px">
                <template #default="scope">
                    <div class="flex padr14">
                       <el-link :href=" '#/info/'+scope.row.id " class="block text1" >{{scope.row.problemProfile}}</el-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            prop="outTradeNo"
            fixed
            min-width="120"
            label="技术请求编号"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            prop="categoryName"
            min-width="140"
            label="产品模块"
            show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            prop="createTime"
            min-width="140"
            sortable
            show-overflow-tooltip
            label="创建时间"
            >
            </el-table-column>
            <el-table-column
            min-width="140"
            prop="updateTime"
            label="上次更新时间"
            show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
            prop="mainContact"
            label="联系人"
            show-overflow-tooltip
            min-width="80"
            >
            </el-table-column>

            <el-table-column
            prop="status"
            label="状态"
            show-overflow-tooltip
            min-width="70"
            >
                <template #default="scoped">
                    {{ statusL[scoped.row.status] }}
                </template>
            </el-table-column>

            <el-table-column
            prop="csiNumber"
            label="客户服务号"
            show-overflow-tooltip
            min-width="120px"
            >
            </el-table-column>
            <el-table-column
            prop="isCollection"
            label="收藏"
            min-width="70">
                <template #default="scoped">
                    <div @click="colect(scoped.row.id,scoped.$index,2)" class="point">
                        <img v-if="scoped.row.isCollection==1" :src="utils.loadImg('xing-a.png')" alt="" srcset="">
                        <img v-else :src="utils.loadImg('xing.png')" alt="" srcset="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            width="100">
                <template #default="scope">
                    <span @click="handleClick(scope.row)" class="point colblue font12">查看详情</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab w100 borbox">
            <p class="font10 cola4">共 {{info2.total}} 条</p>
            <el-pagination
                v-model:currentPage="param2.pageNo"
                @size-change="(val)=>handleSizeChange(val, 2) "
                @current-change="(val)=>handleCurrentChange(val, 2)"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="param2.pageSize"
                layout=" sizes, prev , jumper , next"
                :total="info2.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { orderStatistical, workOrderListNormal, workOrderListTechnology, customerServiceNo, collectionWorkOrder } from "@/utils/api2.js"

export default {
    data(){
        return{
            topL: [
                { txt: '公司总计受理中的工单', num: 'companyTotalAcceptWorkOrderCount' },
                { txt: '我受理中的工单', num: 'myAcceptWorkOrderCount' },
                { txt: '我的已完结工单', num: 'myCloseWorkOrderCount' },
                { txt: '我收藏的工单', num: 'myCollectionWorkOrderCount' },
            ],
            statusL:{1:'待处理', 2:'进行中',3:'待反馈',4:'已关闭',5:'用户请求关闭',6:'支持工作中',7:'研发工作中',8:'待研发反馈',9:'复查更新',10:'客户工作中',11:'方案已提供',12:'内部反馈',13:'已取消'},
            search1:'1',
            search2:'1',
            value1:'',
            value2:'',
            info:{},
            param1:{
                myAccept: false,
                myCollection: false,
                myOngoing: false,
                pageNo:1,
                pageSize: 5,
                problemProfile: '',
                workOrderType: 2,
                customerCSINumber:[],
            },
            param2:{
                myAccept: false,
                myCollection: false,
                myOngoing: false,
                pageNo:1,
                pageSize: 5,
                problemProfile: '',
                workOrderType: 1,
                customerCSINumber:[],
            },
            info1:{
                total: 0,
                voList:[],
            },
            info2:{
                total: 0,
                voList:[],
            },
            workNum:[],
        }
    },
    mounted(){
        this.getData();
        this.getList1();
        this.getList2();
    },
    methods:{
        colect(id,idx,num){
            // console.log(id,idx,num)

            collectionWorkOrder(id).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    if(num==1){
                        let isc1 = this.info1.voList[idx].isCollection;
                        // console.log(isc1)
                        this.info1.voList[idx].isCollection = isc1==1? 0 : 1;
                    }else{
                        let isc2 = this.info2.voList[idx].isCollection;
                        this.info2.voList[idx].isCollection = isc2==1? 0 : 1;
                    }
                }
            });
        },
        cgeBox1(val,key){
            console.log(val,key);
            
            ['myAccept','myCollection','myOngoing'].map(res=>{
                if(res == key){
                    // this.param[res] = val;
                }else{
                    this.param1[res] = false;
                }
            });
            this.getList1();
        },
        cgeBox2(val,key){
            console.log(val,key);
            
            ['myAccept','myCollection','myOngoing'].map(res=>{
                if(res == key){
                    // this.param[res] = val;
                }else{
                    this.param2[res] = false;
                }
            });
            this.getList2();
        },
        getData(){
            orderStatistical().then(res=>{
                console.log(res);
                if(res){
                    this.info = res;
                }
            });

            // 获取客户服务号
            customerServiceNo().then(res=>{
                console.log(res);
                if(res){
                    this.workNum = res;
                }
            })
        },

        getList1(){
            this.loading1 = true;
            if(this.search1=='1'){
                this.param1.problemProfile = this.value1;
                this.param1.outTradeNo = '';
            }else{
                this.param1.problemProfile = '';
                this.param1.outTradeNo = this.value1;
            }
            console.log(this.param1);
            workOrderListTechnology(this.param1).then(res=>{
                this.loading1 = false;
                console.log(res);
                if(res){
                    this.info1 = res;
                }
            })
        },

        getList2(){
            this.loading2 = true;
            if(this.search2=='1'){
                this.param2.problemProfile = this.value2;
                this.param2.outTradeNo = '';
            }else{
                this.param2.problemProfile = '';
                this.param2.outTradeNo = this.value2;
            }
            console.log(this.param2);

            workOrderListNormal(this.param2).then(res=>{
                this.loading2 = false;
                console.log(res);
                if(res){
                    this.info2 = res;
                }
            })
        },
        handleClick(row) {
            console.log(row);
            this.$router.push('/info/'+row.id)
        },
        handleSizeChange(val,num) {
            console.log(`每页 ${val} 条`,num);
            if(num==1){
                this.param1.pageSize = val;
                this.getList1();
            }else{
                this.param2.pageSize = val;
                this.getList2();
            }
        },
        handleCurrentChange(val,num) {
            console.log(`当前页: ${val}`);
            if(num==1){
                this.param1.pageNo = val;
                this.getList1();
            }else{
                this.param2.pageNo = val;
                this.getList2();
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.w300{ width: 300px;}

</style>